<template>
	<div class="app-container">
		<el-card class="elCard">
			<div>
				<i class="el-icon-search"></i>
				<span>查询条件</span>
				<el-button style="float: right; margin-left: 5px;" @click="resetForm('searchForm')" size="small">清空</el-button>
				<el-button style="float: right" @click="searchTable()" type="primary" size="small" icon="el-icon-search">查询</el-button>
			</div>
			<div style="margin-top: 15px">
				<el-form :inline="true" ref="searchForm" :model="searchForm" size="small" label-width="100px">
					<el-form-item label="微信昵称：" prop="niceName">
						<el-input v-model="searchForm.niceName" placeholder="请输入微信昵称"></el-input>
					</el-form-item>
					<el-form-item label="区域：" prop="area">
						<el-input v-model="searchForm.area" placeholder="请输入区域"></el-input>
					</el-form-item>
					<el-form-item label="是否启用：" prop="status">
						<el-select v-model="searchForm.status" clearable placeholder="请选择" autocomplete="off" style="max-width: 200px;">
						    <el-option v-for="item in statusArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
		<el-row>
			<el-button type="primary" size="medium" icon="el-icon-s-data" @click="sameDay()">当天积分最高</el-button>
			<el-button type="primary" size="medium" icon="el-icon-s-data" @click="sameWeek()">当周积分最高</el-button>
			<el-popover placement="bottom" width="150" v-model="visible" style="float: right;">
				<el-checkbox-group v-model="checkList" @change="checkedChange">
				    <el-checkbox v-for="item in checkArr" :label="item.val">{{item.label}}</el-checkbox>
				</el-checkbox-group>
				<el-button type="primary" size="medium" icon="el-icon-s-grid" circle slot="reference"></el-button>
			</el-popover>
			<el-button style="float: right; margin-right: 5px;" size="medium" type="primary" icon="el-icon-refresh-left" circle @click="page = 1;getList()"></el-button>
		</el-row>
		<el-card>
			<el-table ref="tableList" :data="tableData" :stripe="true" border v-loading="listLoading" :default-sort = "{prop: 'integral', order: 'descending'}" style="width: 100%" @selection-change="handleSelectionChange">
				<!-- <el-table-column type="selection" width="55"></el-table-column> -->
				<el-table-column v-for="item in checkArr" v-if="item.dis" :prop="item.val" :sortable="item.val == 'integral' || item.val == 'todayIntegral' || item.val == 'weekIntegral' ? true : false" :label="item.label" min-width="110">
					<template slot-scope="scope">
						<span v-if="item.val == 'headImg'"><el-avatar size="medium" :src="scope.row.headImg"></el-avatar></span>
						<span v-else-if="item.val == 'status'">{{scope.row.status == 0 ? '禁用' : '启用'}}</span>
						<span v-else>{{scope.row[item.val]}}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="118" fixed="right">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" icon="el-icon-edit" @click="gameClick(scope.row)">积分明细</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-pagination v-if="total != -1" @current-change="handleCurrentChange" :current-page="page" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</el-card>
		<el-dialog title="积分明细" :visible.sync="dialogFormVisibles" :append-to-body="true">
			<el-card style="margin-bottom: 20px;">
				<div>
					<i class="el-icon-search"></i>
					<span>查询条件</span>
					<el-button style="float: right; margin-left: 5px;" @click="resetFormS('searchFormS')" size="small">清空</el-button>
					<el-button style="float: right" @click="searchTableS()" type="primary" size="small" icon="el-icon-search">查询</el-button>
				</div>
				<div style="margin-top: 15px">
					<el-form :inline="true" ref="searchFormS" :model="searchFormS" size="small" label-width="100px">
						<el-form-item label="获取途径：" prop="type">
							<!-- <el-input v-model="searchFormS.type" placeholder="请输入获取途径"></el-input> -->
							<el-select v-model="searchFormS.type" clearable placeholder="请选择" autocomplete="off" style="max-width: 200px;">
							    <el-option v-for="item in typeArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
					</el-form>
				</div>
			</el-card>
			<el-table :data="tableDataS" :stripe="true" border v-loading="listLoadingS" style="width: 100%">
				<el-table-column prop="createdTxStamp" label="时间"></el-table-column>
				<el-table-column prop="type" label="获取途径"></el-table-column>
				<el-table-column prop="integralNum" label="积分"></el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-pagination @current-change="handleCurrentChangeS" :current-page="pageS" layout="total, prev, pager, next, jumper" :total="totalS"></el-pagination>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				page: 1,
				pageS: 1,
				searchForm: {
					niceName: '',
					area: '',
					status: 1
				},//条件查询form
				searchFormS: {
					type: ''
				},//获取途径查询
				statusArr: [{
					value: 0,
					label: "禁用"
				}, {
					value: 1,
					label: "启用"
				}],//是否启用
				typeArr: [],//积分明细-获取途径
				tableData: [],//表格
				tableDataS: [],//积分明细表格
				multipleSelection: [],//表格选中数组
				total: 0,//分页总条数
				totalS: 0,
				visible: false,
				checkArr: [
					{label: "微信头像", val: "headImg", dis: true},
					{label: "微信昵称", val: "niceName", dis: true},
					{label: "区域", val: "area", dis: true},
					{label: "积分总数", val: "integral", dis: true},
					{label: "当天获取积分", val: "todayIntegral", dis: true},
					{label: "当周获取积分", val: "weekIntegral", dis: true},
					{label: "是否启用", val: "status", dis: true}
				],
				checkList: ["headImg", "niceName", "area", "integral", "todayIntegral", "weekIntegral", "status"],
				dialogFormVisibles: false,//积分明细弹窗
				listLoading: false,
				listLoadingS: false,
				wxUserId: ''
			}
		},
		components: {
			
		},
		created(){
			this.getList();
		},
		methods:{
			getList() {
				this.listLoading = true;
				// 查询内容转数组
				// let obj = this.searchForm;
				// let arr = Object.keys(obj);
				// let arrs = [];
				// arr.forEach(item => {
				// 	let o = {};
				// 	o.col = item;
				// 	o.val = obj[item];
				// 	if(item == "niceName") {
				// 		o.type = "like";
				// 	} else {
				// 		o.type = "=";
				// 	}
				// 	arrs.push(o);
				// })
				
				let data = {
					// entity: 'FaExamUser',
					pageNumber: this.page,
					pageSize: 10,
					niceName: this.searchForm.niceName,
					area: this.searchForm.area,
					status: this.searchForm.status
					// filter: JSON.stringify(arrs),
					// sidx: 'sort',
					// sord: 'desc'
				}
				this.$comjs.ajax.getAjax('/fa/exam/user/integral/record/query', data, this, res => {
					this.tableData = res.list;
					this.total = res.total;
					this.listLoading = false;
				});
			},
			getListS() {
				this.listLoadingS = true;
				let data = {
					wxUserId: this.wxUserId,
					pageNumber: this.pageS,
					type: this.searchFormS.type,
					pageSize: 10
				}
				this.$comjs.ajax.getAjax('/fa/user/integral-detail', data, this, res => {
					this.tableDataS = res.list;
					this.totalS = res.total;
					this.listLoadingS = false;
				});
			},
			// 搜索
			searchTable() {
				this.page = 1;
				this.getList();
			},
			searchTableS() {
				this.pageS = 1;
				this.getListS();
			},
			// 重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.page = 1;
				this.getList();
			},
			resetFormS(formName) {
				this.$refs[formName].resetFields();
				this.pageS = 1;
				this.getListS();
			},
			// 当天积分最高
			sameDay() {
				this.listLoading = true;
				this.$refs.tableList.sort('todayIntegral', 'descending');
				let data = {
					type: 1
				}
				this.$comjs.ajax.getAjax('/fa/user/integral-sum', data, this, res => {
					this.tableData = res.list;
					this.total = -1;
					this.listLoading = false;
				});
			},
			// 当周积分最高
			sameWeek() {
				this.listLoading = true;
				this.$refs.tableList.sort('weekIntegral', 'descending');
				let data = {
					type: 2
				}
				this.$comjs.ajax.getAjax('/fa/user/integral-sum', data, this, res => {
					this.tableData = res.list;
					this.total = -1;
					this.listLoading = false;
				});
			},
			// 表格选中
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			// 分页
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			handleCurrentChangeS(val) {
				this.pageS = val;
				this.getListS();
			},
			// 多选框
			checkedChange(value) {
				let arr = this.checkArr;
				arr.forEach(item => {
					item.dis = false;
				})
				value.forEach(item => {
					let index = arr.findIndex(d => d.val === item);
					arr[index].dis = true;
				})
				this.checkArr = arr;
			},
			// 积分明细
			gameClick(row) {
				this.wxUserId = row.wxUserId;
				this.pageS = 1;
				this.dialogFormVisibles = true;
				
				this.$comjs.ajax.getAjax('/fa/exam/user/integral/record/integral-type', {}, this, res => {
					this.typeArr = res.list;
				});
				this.getListS();
			}
		},
		computed:{
			
		}
	}
</script>

<style lang="scss" scoped>
	.app-container {
		padding: 20px;
	}
	.elCard {
		margin-top: 20px;
	}
	.el-row {
		padding: 20px 0;
	}
	.el-pagination {
		margin-top: 15px;
		text-align: left;
	}
</style>

